import React, { useState, useEffect } from "react";
import { FormattedDate, FormattedMessage } from "gatsby-plugin-intl";
import Layout from "../components/layout";
import { getInbox, markCommentRead, markDeliverableCommentRead } from "../services/getRequest";
import { Link, navigate } from "gatsby";

interface Props {
  location: Location;
}

const Inbox = ({ location }: Props) => {
  const [inboxItems, setInboxItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      getInbox()
        .then((inbox) => {
          setIsLoading(false);
          setInboxItems(inbox);
        })
        .catch((error) => {
          setIsLoading(false);
          setInboxItems([]);
        });
    }
  }, [isLoading]);

  return (
    <Layout>
      <h1 className="block-title">
        <FormattedMessage id="inbox" defaultMessage="Inbox" />
      </h1>

      {isLoading ? (
        <div className="alert alert-secondary" role="alert">
          <p>
            <FormattedMessage id="loading" defaultMessage="Loading" />
          </p>
        </div>
      ) : inboxItems && !!inboxItems.length ? (
        inboxItems
          .filter((item) => !!item)
          .map((item) => {
            return (
              <div className={`card mb-3`}>
                <div className="card-header">
                  <div className="d-flex">
                    <div>{item["comment"].updatedBy["name"]}</div>
                    <div className="ms-auto">
                      <FormattedDate
                        value={item["comment"].timestamp}
                        year="numeric"
                        month="short"
                        day="2-digit"
                        hour="numeric"
                        minute="numeric"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <p>{item["comment"].text}</p>
                  {item.deliverableId ? (
                    // <Link
                    //   to={`/projects`}
                    //   state={{
                    //     commentId: item["commentId"],
                    //     deliverableId: item["deliverableId"],
                    //   }}
                    //   className={`card-link btn btn-primary`}
                    // >
                    //   <FormattedMessage
                    //     id="view_comment"
                    //     defaultMessage="View Comment"
                    //   />
                    // </Link>
                    <button
                      className={`card-link btn btn-primary`}
                      type="button"
                      onClick={() => {
                        markDeliverableCommentRead(
                          item["projectId"],
                          item["deliverableId"],
                          item["commentId"]
                        ).then(() =>
                          navigate(`/projects`, {
                            state: { projectId: item["projectId"], commentId: item["commentId"], deliverableId: item["deliverableId"]},
                          })
                        );
                      }}
                    >
                      <FormattedMessage
                        id="view_comment"
                        defaultMessage="View Comment"
                      />
                    </button>
                  ) : (
                    <button
                      className={`card-link btn btn-primary`}
                      type="button"
                      onClick={() => {
                        markCommentRead(
                          item["projectId"],
                          item["commentId"]
                        ).then(() =>
                          navigate(`/project?id=${item["projectId"]}`, {
                            state: { commentId: item["commentId"] },
                          })
                        );
                      }}
                    >
                      <FormattedMessage
                        id="view_comment"
                        defaultMessage="View Comment"
                      />
                    </button>
                  )}
                </div>
              </div>
            );
          })
      ) : (
        <div className="alert alert-secondary" role="alert">
          <p>
            <FormattedMessage
              id="no_inbox_items"
              defaultMessage="Your inbox is empty"
            />
          </p>
        </div>
      )}
    </Layout>
  );
};

export default Inbox;
